
import { defineComponent } from "vue-demi";

export default defineComponent({
  name: "CommonQuestionnaireTemplateChart",
  props: ["number", "chart", "course"],
  methods: {
    numberWithSpaces(x: number) {
      x = Number(x);
      return x.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
  },
});
